import { useState } from 'react';

import type { CellComponent } from '@/features/RidesTable/types';

import { DotsVerticalIcon } from '@radix-ui/react-icons';
import * as Popover from '@radix-ui/react-popover';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';

import { useCompleteRideMutation } from '@/api';
import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { isCommunity, isScheduled } from '@/path_defs';
import { pushFlashMessage } from '@/store/flashMessagesSlice';
import {
  popToastNotification,
  pushToastNotification,
} from '@/store/toastNotificationsSlice';

import RideCancelModal from './CancelModal';
import DeclineModal from './DeclineModal';
import OldSubmitETAModal from './OldSubmitETAModal';
import ReleaseModal from './ReleaseModal';
import SubmitETAModal from './SubmitETAModal';

import './main.scss';

import type { DispatcherRideStatusBadge, GlobalSSRData } from '@/types';

import { DISPATCHER_RIDE_STATUS_BADGE_TEXT } from '@/features/RideStatusBadge/types';

const QuickActionCell: CellComponent = ({ label, ride }) => {
  const {
    reactDashboardQuickActionsSubmitEta = false,
    reactDashboardQuickActionsSingleRide = false,
    bLegEtaSubmission = false,
  } = useFlags();

  const { isDriver } = (window as GlobalSSRData)._unsafeSSRData.global; // eslint-disable-line no-underscore-dangle

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const [showSubmitRideETAModal, setShowSubmitRideETAModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const { removeRideById } = useTableContext();

  // const { text } = useStatusBadge(ride);
  const text =
    DISPATCHER_RIDE_STATUS_BADGE_TEXT[
      ride.statusBadge as DispatcherRideStatusBadge
    ];

  const etaSubmitted = text === DISPATCHER_RIDE_STATUS_BADGE_TEXT.responded;

  const showDeclineOption =
    text !== DISPATCHER_RIDE_STATUS_BADGE_TEXT.declined &&
    text !== DISPATCHER_RIDE_STATUS_BADGE_TEXT.in_progress;
  const showSubmitETA =
    text !== DISPATCHER_RIDE_STATUS_BADGE_TEXT.not_started &&
    text !== DISPATCHER_RIDE_STATUS_BADGE_TEXT.processing;

  const [completeRide] = useCompleteRideMutation();

  const dispatch = useDispatch();

  const handleCompleteRide = () => {
    completeRide(ride.id)
      .unwrap()
      .then(() => {
        removeRideById(ride.id);
        dispatch(
          pushToastNotification({
            text: 'Ride successfully completed.',
            rideId: ride.id,
            role: 'dispatcher',
            action: 'view ride',
          }),
        );
        setTimeout(() => {
          dispatch(
            popToastNotification({
              text: 'Ride successfully canceled.',
            }),
          );
        }, 5000);
      })
      .catch(() => {
        dispatch(
          pushFlashMessage({
            text: 'Sorry, this ride cannot be completed at the moment. Please try again in a few minutes.',
            type: 'error',
          }),
        );
      });
  };

  return (
    <td className="quick-action" data-label={label} aria-label={label}>
      <RideCancelModal
        hide={() => setShowCancelModal(false)}
        show={showCancelModal}
        rideId={ride.id}
      />
      <DeclineModal
        hide={() => setShowDeclineModal(false)}
        show={showDeclineModal}
        rideId={ride.id}
      />
      <ReleaseModal
        hide={() => setShowReleaseModal(false)}
        show={showReleaseModal}
        rideId={ride.id}
      />
      {bLegEtaSubmission && showSubmitRideETAModal && (
        <SubmitETAModal
          hide={() => setShowSubmitRideETAModal(false)}
          rideDirection={ride.direction}
          rideId={ride.id}
          rideTimezone={ride.timezone}
          rideType={ride.rideType}
          rideStartTime={new Date(ride.startTime)}
          show={showSubmitRideETAModal}
          streetAddress={ride.pickupLocation.streetAddress}
          tripId={ride.tripId}
        />
      )}
      {!bLegEtaSubmission && showSubmitRideETAModal && (
        <OldSubmitETAModal
          hide={() => setShowSubmitRideETAModal(false)}
          show={showSubmitRideETAModal}
          rideId={ride.id}
          timezone={ride.timezone}
          rideStartTime={new Date(ride.startTime)}
          streetAddress={ride.pickupLocation.streetAddress}
        />
      )}
      {reactDashboardQuickActionsSingleRide && (
        <div className="quick-action">
          <Popover.Root open={openDropdown} onOpenChange={setOpenDropdown}>
            <Popover.Trigger asChild>
              <button
                className="IconButton"
                aria-label="Customise options"
                type="button"
              >
                <DotsVerticalIcon />
              </button>
            </Popover.Trigger>

            <Popover.Portal>
              <Popover.Content
                className="popover-content"
                align="end"
                sideOffset={5}
              >
                <div className="d-flex flex-column" style={{ gap: 10 }}>
                  {reactDashboardQuickActionsSubmitEta &&
                    showSubmitETA &&
                    (isCommunity() ||
                      text ===
                        DISPATCHER_RIDE_STATUS_BADGE_TEXT.submit_eta) && (
                      <label
                        htmlFor="submit_eta"
                        className="quick-action-option"
                        onClick={() => {
                          setOpenDropdown(false);
                          setShowSubmitRideETAModal(true);
                        }}
                      >
                        {etaSubmitted ? 'Update ETA' : `Submit ETA`}
                      </label>
                    )}
                  {isScheduled() && (
                    <label
                      htmlFor="complete"
                      className="quick-action-option"
                      onClick={handleCompleteRide}
                    >
                      Complete
                    </label>
                  )}
                  {isScheduled() && (
                    <label
                      htmlFor="release"
                      className="quick-action-option"
                      onClick={() => {
                        setOpenDropdown(false);
                        setShowReleaseModal(true);
                      }}
                    >
                      Release
                    </label>
                  )}
                  {isScheduled() && !isDriver && (
                    <label
                      htmlFor="cancel"
                      className="quick-action-option -negative"
                      onClick={() => {
                        setOpenDropdown(false);
                        setShowCancelModal(true);
                      }}
                    >
                      Cancel
                    </label>
                  )}
                  {isCommunity() && showDeclineOption && (
                    <label
                      htmlFor="decline"
                      className="quick-action-option -negative"
                      onClick={() => {
                        setOpenDropdown(false);
                        setShowDeclineModal(true);
                      }}
                    >
                      Decline
                    </label>
                  )}
                </div>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
      )}
    </td>
  );
};

export default QuickActionCell;
