import * as React from 'react';

import { QueryStatus } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

import {
  useGetRoundtripDetailsQuery,
  useSubmitRideResponseMutation,
} from '@/api';
import { pushFlashMessage } from '@/store/flashMessagesSlice';

import './main.scss';

import type { Ride } from '@/types';

import DateField from '@root/components/DateField';
import TimeField from '@root/components/TimeField';

import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import * as logger from '@/lib/@datadog/browser-logs';
import * as Switch from '@/lib/@radix-ui/react-switch';
import { pushToastNotification } from '@/store/toastNotificationsSlice';
import { AVAILABLE_STATUSES, RIDE_TYPES } from '@/types';
import {
  formatRideTime,
  formatTimehhmmA,
  getShortTimezoneAbbreviation,
} from '@/utils/dateTime';

import Modal from './Modal';

interface submitETAParams {
  bidType?: string;
  eta?: Date;
  rideId: number;
}

interface SecondaryRide {
  dateField?: Date;
  returnTime?: Date;
  ride?: Ride;
  timeField?: Date;
}

interface Props {
  hide: () => void;
  rideDirection: string;
  rideId: number;
  rideStartTime: Date;
  rideTimezone: string;
  rideType: string;
  show: boolean;
  streetAddress: string;
  tripId: number;
}

const computeMinTime = (day: Date, startTime: Date) => {
  const selectedDateStr = day.toDateString();
  const rideDateStr = new Date(startTime).toDateString();

  if (selectedDateStr === rideDateStr) {
    return new Date(startTime);
  }

  const newMinTime = new Date(day);
  newMinTime.setHours(0, 0, 0, 0);
  return newMinTime;
};

const END_OF_DAY = '23:59:59.999Z';
const timestamp = (time: string) => new Date(time).toISOString().split('T')[1];

const SubmitETAModal = ({
  rideDirection,
  rideId,
  rideStartTime,
  rideType,
  hide,
  show,
  streetAddress,
  rideTimezone,
  tripId,
}: Props) => {
  const dispatch = useDispatch();

  const oppositeRideDirection =
    rideDirection === 'outbound' ? 'return' : 'outbound';

  const timeZoneAbbreviation = getShortTimezoneAbbreviation(
    rideStartTime,
    rideTimezone,
  );

  const [dateField, setDateField] = React.useState(rideStartTime);
  const [timeField, setTimeField] = React.useState(rideStartTime);
  const [secondaryRide, setSecondaryRide] = React.useState<SecondaryRide>({});
  const [willCallReturnRide, setwillCallReturnRide] =
    React.useState<Ride | null>(null);
  const [submitSecondaryRide, setSubmitSecondaryRide] = React.useState(true);

  const updateSecondaryRideDateField = (newDateField: Date) => {
    setSecondaryRide((prevSecondaryRide) => ({
      ...prevSecondaryRide,
      dateField: newDateField,
    }));
  };
  const updateSecondaryRideTimeField = (newTimeField: Date) => {
    setSecondaryRide((prevSecondaryRide) => ({
      ...prevSecondaryRide,
      timeField: newTimeField,
    }));
  };
  const toggleSwitch = () =>
    setSubmitSecondaryRide((prevSwitch) => !prevSwitch);

  const { updateRideById } = useTableContext();

  const [submitRideResponse] = useSubmitRideResponseMutation();
  const roundtripDetailsRes = useGetRoundtripDetailsQuery(
    {
      tripId,
      statusBadge: [
        AVAILABLE_STATUSES.available,
        AVAILABLE_STATUSES.processing,
        AVAILABLE_STATUSES.willCall,
      ].join(','),
    },
    {
      skip: rideType !== RIDE_TYPES.roundTrip,
    },
  );

  const { returnTime } = React.useMemo(() => {
    const populateSecondaryRideInfo = (rides: Ride[], timeZone: string) => {
      const oppositeRide = rides.find(
        (ride) => ride.direction !== rideDirection,
      );

      if (!oppositeRide) {
        return {
          returnTime: null,
        };
      }

      const isWillCall = timestamp(oppositeRide.startTime) === END_OF_DAY;

      if (isWillCall && oppositeRide.direction === 'return') {
        setwillCallReturnRide(oppositeRide);
        return {
          returnTime: null,
        };
      }

      if (!isWillCall) {
        const d = new Date(oppositeRide.startTime);
        setSecondaryRide({
          ride: oppositeRide,
          dateField: d,
          timeField: d,
          returnTime: d,
        });
      }

      return {
        returnTime: formatTimehhmmA(oppositeRide.startTime, timeZone),
      };
    };

    if (
      roundtripDetailsRes.status !== QueryStatus.fulfilled ||
      !roundtripDetailsRes?.data?.rides
    ) {
      return {
        returnTime: null,
      };
    }

    return populateSecondaryRideInfo(
      roundtripDetailsRes.data?.rides,
      rideTimezone,
    );
  }, [
    rideDirection,
    rideTimezone,
    roundtripDetailsRes.data?.rides,
    roundtripDetailsRes.status,
  ]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const combinedDate = formatRideTime(dateField, timeField);

    const submitETAParams: submitETAParams[] = [
      {
        rideId,
        eta: combinedDate,
      },
    ];
    const shouldSubmitBLeg =
      submitSecondaryRide &&
      secondaryRide &&
      secondaryRide.ride &&
      secondaryRide.dateField &&
      secondaryRide.timeField;

    if (shouldSubmitBLeg && secondaryRide?.ride) {
      submitETAParams.push({
        rideId: secondaryRide.ride.id,
        eta: formatRideTime(secondaryRide.dateField!, secondaryRide.timeField!),
      });
    }

    if (submitSecondaryRide && secondaryRide && willCallReturnRide) {
      submitETAParams.push({
        rideId: willCallReturnRide.id,
        bidType: 'will_answer',
      });
    }
    const multipleRideETASubmissions = submitETAParams.length > 1;

    const submitPromises = submitETAParams.map((param) =>
      submitRideResponse(param)
        .unwrap()
        .then(() => {
          if (!multipleRideETASubmissions) {
            dispatch(
              pushToastNotification({
                text: 'Your ETA was successfully submitted.',
                rideId: param.rideId,
                role: 'dispatcher',
                action: 'view ride',
              }),
            );
          }
          updateRideById(param.rideId, { hasBid: true });
        })
        .catch((err) => {
          logger.error(JSON.stringify(err));
          if (!multipleRideETASubmissions) {
            dispatch(
              pushFlashMessage({
                text: 'Sorry, an ETA for this ride cannot be submitted at the moment. Please try again in a few minutes.',
                type: 'error',
              }),
            );
          }
        }),
    );

    try {
      await Promise.all(submitPromises);

      hide();
      if (multipleRideETASubmissions) {
        dispatch(
          pushToastNotification({
            text: `ETAs successfully submitted.`,
            action: 'success',
          }),
        );
      }
    } catch (err) {
      logger.error(JSON.stringify(err));
      if (multipleRideETASubmissions) {
        dispatch(
          pushFlashMessage({
            text: 'An error occurred while submitting ETAs for these rides. Please try again.',
            type: 'error',
          }),
        );
      }
    }
  };

  return (
    <Modal
      open={show}
      hide={hide}
      title="Submit ETA"
      subtitle={`When can you arrive at ${streetAddress}?`}
    >
      <form
        className="form p-0"
        id="submit-ride-eta"
        acceptCharset="UTF-8"
        method="submit"
        onSubmit={handleSubmit}
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <div className="d-flex flex-column flex-grow-1">
              <DateField
                initialDate={rideStartTime}
                minDate={rideStartTime}
                onChange={setDateField}
              />
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <TimeField
                timeInterval="5"
                initialTime={rideStartTime}
                minTime={computeMinTime(dateField, rideStartTime)}
                onChange={setTimeField}
                position="top-end"
                tripTimezone={rideTimezone}
              />
            </div>
          </div>
          {rideType === 'round_trip' &&
          (secondaryRide.ride || willCallReturnRide) ? (
            <div>
              <div className="d-flex flex-row">
                <div className="d-flex flex-justify-between mb-6 pt-4">
                  <label
                    className="switch mt-1"
                    aria-label="submit return ride eta"
                    htmlFor="submit-return-ride-eta"
                  >
                    <Switch.Root
                      className="switch-root"
                      id="submit-return-ride-eta"
                      checked={submitSecondaryRide}
                      onCheckedChange={toggleSwitch}
                    >
                      <Switch.Thumb className="switch-thumb" />
                    </Switch.Root>
                  </label>
                  <div>
                    {willCallReturnRide && (
                      <span className="bold">
                        This trip includes a will call return ride.{' '}
                      </span>
                    )}
                    {secondaryRide.ride && (
                      <span className="bold">
                        Would you like to submit an ETA for the{' '}
                        {oppositeRideDirection} ride?{' '}
                      </span>
                    )}
                    <button
                      type="button"
                      className="bold link -blue -underline"
                      onClick={() => {
                        window.location.href = willCallReturnRide
                          ? `/dispatcher/ride/${willCallReturnRide.id}`
                          : `/dispatcher/ride/${secondaryRide.ride!.id}`;
                      }}
                    >
                      View Ride
                    </button>

                    {willCallReturnRide && (
                      <div className="d-flex flex-row text-xs">
                        {`The return will be assigned to you as a will call ride if you are awarded the ${rideDirection}.`}
                      </div>
                    )}
                    {secondaryRide.ride && (
                      <div className="d-flex flex-row text-xs">
                        {`Requested time for ${oppositeRideDirection} ride: ${returnTime} ${timeZoneAbbreviation}`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {submitSecondaryRide &&
              secondaryRide.dateField &&
              secondaryRide.timeField &&
              secondaryRide.returnTime ? (
                <div className="d-flex flex-row">
                  <DateField
                    initialDate={secondaryRide.dateField}
                    minDate={secondaryRide.returnTime}
                    onChange={updateSecondaryRideDateField}
                  />
                  <TimeField
                    timeInterval="5"
                    initialTime={secondaryRide.returnTime}
                    minTime={computeMinTime(
                      secondaryRide.dateField,
                      secondaryRide.returnTime,
                    )}
                    onChange={updateSecondaryRideTimeField}
                    position="top-end"
                    tripTimezone={rideTimezone}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="alert-dialog-actions mt-6 flex-row">
            <button
              type="button"
              className="button -medium -inverted-blue"
              onClick={hide}
            >
              Nevermind
            </button>

            <button
              data-test="submit-ride-eta"
              type="submit"
              className="button -medium"
              disabled={willCallReturnRide ? !submitSecondaryRide : false}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default SubmitETAModal;
