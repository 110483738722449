import { useState } from 'react';

import type { GlobalSSRData, RideType, UnsafeSSRData } from '@/types';

import * as AlertDialog from '../../lib/@radix-ui/react-alert-dialog';
import SubmitETAModal from '../RidesTable/QuickActionCell/SubmitETAModal';

interface SubmitETA {
  etaSubmitted: boolean;
  rideDirection: string;
  rideId: number;
  rideStartTime: Date;
  rideTimezone: string;
  rideType: RideType;
  streetAddress: string;
  tripId: number;
}

type SubmitETASSRProps = UnsafeSSRData & { SubmitETAModalLink: SubmitETA };

const SubmitETAModalLink = () => {
  const {
    etaSubmitted,
    rideDirection,
    rideId,
    rideStartTime,
    rideTimezone,
    rideType,
    streetAddress,
    tripId,
  } = ((window as GlobalSSRData)._unsafeSSRData as SubmitETASSRProps)
    .SubmitETAModalLink;
  const [showSubmitRideETAModal, setShowSubmitRideETAModal] = useState(false);

  return (
    <div className="d-inline">
      <SubmitETAModal
        hide={() => setShowSubmitRideETAModal(false)}
        rideDirection={rideDirection}
        rideId={rideId}
        rideType={rideType}
        rideTimezone={rideTimezone}
        rideStartTime={new Date(rideStartTime)}
        show={showSubmitRideETAModal}
        streetAddress={streetAddress}
        tripId={tripId}
      />
      <AlertDialog.Root>
        <AlertDialog.Trigger asChild>
          <button
            className="button -small ml-1 -mid submit-btn width-sm-auto col-6"
            type="button"
            onClick={() => setShowSubmitRideETAModal(true)}
          >
            {etaSubmitted ? 'Update ETA' : 'Submit ETA'}
          </button>
        </AlertDialog.Trigger>
      </AlertDialog.Root>
    </div>
  );
};

export default SubmitETAModalLink;
